<template>
    <div class="_banner fn-clear">
        <div class="wrap-1000">
            <!-- <div class="left fn-clear">
                <router-link :to="{name: 'Detail'}" ><img src="https://d25le42s0o73x7.cloudfront.net/images/banner/side-siki2.jpg" style="margin-top:5px;"  alt=" 敷鉄板　5×10　5×20"></router-link>
                <router-link :to="{name: 'Detail'}" ><img src="https://d25le42s0o73x7.cloudfront.net/images/banner/side_taikousei2.jpg" style="margin-top:5px;"  alt=" 耐候性大型土のう"></router-link>
                <router-link :to="{name: 'Detail'}" ><img src="https://d25le42s0o73x7.cloudfront.net/images/banner/side_flecon2.jpg" style="margin-top:5px;"  alt=" フレコンバック"></router-link>
                <router-link :to="{name: 'Detail'}" ><img src="https://d25le42s0o73x7.cloudfront.net/images/banner/syanetsu_banner_top-side_0625.jpg" style="margin-top:5px;"  alt=" 遮熱シート"></router-link>
            </div> -->
    
            <div class="right fn-clear">
            
            <el-carousel :interval="5000" arrow="always" class="slider_wrapper full_page_photo" height="426px">
                <el-carousel-item v-for="(item,index) in items" :key="index">
                    <div class="container">
                        <img :src=item.img>
                    </div> 
                </el-carousel-item>
		    </el-carousel> 
            <!-- <div class="rightdown">
                <ul>
                    <li>
                    <router-link :to="{name: 'Detail'}" ><img src="https://d25le42s0o73x7.cloudfront.net/upload/bayl_image/09181342_5d81b5ceaa0ab.jpg" alt="ブルーシート"></router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'Detail'}" ><img src="https://d25le42s0o73x7.cloudfront.net/upload/bayl_image/09181343_5d81b5f8d729f.jpg" alt="牧草サイレージ用ラップフィルム"></router-link>
                    </li>
                </ul>                   
            </div> -->
            
        </div>
                

    </div>
</div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator"
@Component
export default class carousel extends Vue {
    items = [
        {title:"国際貿易・越境EC", contact:"・輸出入業務 ・通信販売事業（ネット通販・カタログ通販）",img:require("../assets/carousel/dianshang.png")},
        {title:"新規事業", contact:"・日用品雑貨卸売業・製品開発（国内・海外パートナーとOEM製品の共同開発）",img:require("../assets/carousel/shiye.png")},
        {title:"ソフトウェア開発", contact:"・技術力、提案力、コンサルティング力",img:require("../assets/carousel/jishu.png")}
    ]
}
</script>

<style lang="scss" scoped>
._banner {
	margin: 5px 0;
	width: 100%;
	height: 426px;
	background-color: #f2f2f2;
    display: block;
}
.left {
    width: 245px;
    position: absolute;
}
.right {
    /* width: 735px; */
    width: 1000px;
    float: right;
    position: relative;
}
.el-carousel__item:nth-child(3n) {
	display:block;
	height:auto;
	background-size: 100%;
}
.el-carousel__item:nth-child(3n+1) {
	display:block;
	max-width:100%;
	height:auto;
	background-size: 100%;
}
.el-carousel__item:nth-child(3n+2) {
	display:block;
	max-width:100%;
	height:auto;
	background-size: 100%;
}
.container{
	position: relative;
    z-index: 9;
    text-align: left;
}
.call_to_action{
    margin: 0;
    max-width: 960px;
    padding: 0;
    text-align: left;
    color: #fff;
    position: absolute;
    bottom: 10px;
    h3{
        font-weight: 300;
        font-size: 50px;
        line-height: 55px;
        padding: 16px 30px;
        margin: 0;
        background: #000;
        background: rgba(0,0,0,.6);
        display: inline-block;
        clear: both;
    }
    h4{
        font-weight: 300;
        font-size: 26px;
        line-height: 30px;
        margin: 0 0 20px;
        padding: 16px 30px;
        background: #000;
        background: rgba(0,0,0,.6);
        display: inline-block;
        clear: both;
    }
}
.rightdown{
    ul{
        margin-right: -10px;
        margin-top: 5px;
        list-style: none;
        li{
            width: 362px;
            height: 110px;
            float: left;
            margin: 0 10px 10px 0px;
        }
    }
}
</style>>

